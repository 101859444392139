<template>
  <b-card
    class="card card-custom gutter-b"
    header-text-variant="white"
    header-tag="header"
    header-bg-variant="primary"
  >
    <template #header>
      <h5 class="mb-0" v-html="communityPageTitle()"></h5>
    </template>
    <div v-if="profileActivityResponses.length > 0">
      <b-card-text
        class="font-size-normal"
        v-for="(response, index) in profileActivityResponses"
        v-bind:key="index"
      >
        <router-link
          :to="{ path: `/activity/${response.activity.id}` }"
          v-if="response.activity.activity_type.id !== 9"
          class="d-flex justify-content-between"
        >
          {{ $t("COMMUNITY.MY_PAGE.POST_TO") }} {{ response.activity.title }}
          <span class="text-muted">
            <GenericDatetimeDisplay
              :long="true"
              :date="response.created_at"
              :lastUpdated="false"
              :relative="true"
              :createdAt="true"
            >
            </GenericDatetimeDisplay>
          </span>
        </router-link>
        <div v-else class="d-flex justify-content-between">
          {{ $t("COMMUNITY.MY_PAGE.POST_TO") }} {{ response.activity.title }}
          <span class="text-muted">
            <GenericDatetimeDisplay
              :long="true"
              :date="response.created_at"
              :lastUpdated="false"
              :relative="true"
              :createdAt="true"
            >
            </GenericDatetimeDisplay>
          </span>
        </div>
      </b-card-text>
    </div>

    <div v-else>
      <b-alert show variant="transparent">
        <div class="text-center">
          No Activities Found
        </div>
      </b-alert>
    </div>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import GenericDatetimeDisplay from "@/modules/together-helpers/components/generic-displays/GenericDatetimeDisplay";

export default {
  name: "ViewMember",

  components: {
    GenericDatetimeDisplay
  },

  methods: {
    communityPageTitle() {
      if (this.userId == this.currentUserId) {
        return this.$t("COMMUNITY.MY_PAGE.TITLE");
      } else {
        if (this.profileActivityResponses[0]) {
          let prefix = this.$t("COMMUNITY.MY_PAGE.TITLE_PREFIX");
          let suffix = this.$t("COMMUNITY.MY_PAGE.TITLE_SUFFIX");
          let computedUserName = this.profileActivityResponses[0].user
            .display_name;
          return `${prefix} ${computedUserName} ${suffix}`;
        } else {
          return undefined;
        }
      }
    }
  },

  computed: {
    ...mapGetters(["currentUser", "profileActivityResponses"]),
    userId: function() {
      return this.$route.params.id;
    },
    currentUserId: function() {
      return this.currentUser.id;
    }
  }
};
</script>

<style lang="scss" scoped></style>
